import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL_TES } from "../../config/index";
import { useNavigate } from "react-router-dom";
import { HiOutlineSave, HiOutlineArrowLeft } from "react-icons/hi";
import { useParams } from "react-router-dom";

import TextArea from "../../components/Form2/TextArea";
import Radio from "../../components/Form2/Radio";

import useSWRMutation from "swr/mutation";
import { sendRequestPut } from "../../swr";
import Loading from "../Loading";

const KonfirmasiIndex = () => {
  const { id } = useParams();

  const [keterangan, setKeterangan] = useState("");
  const [telpDiangkat, settelpDiangkat] = useState("");
  const [responDitelp, setresponDitelp] = useState("");
  const [responBaik, setresponBaik] = useState("");

  const itemtelpDiangkat = [
    {
      name: "Iya",
      value: "Y",
    },
    {
      name: "Tidak",
      value: "N",
    },
  ];

  const itemresponDitelp = [
    {
      name: "Iya",
      value: "Y",
    },
    {
      name: "Tidak",
      value: "N",
    },
  ];

  const itemresponBaik = [
    {
      name: "Iya",
      value: "Y",
    },
    {
      name: "Tidak",
      value: "N",
    },
  ];

  const navigate = useNavigate();

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/psikolog/konseling-konfirm/${id}`,
    sendRequestPut
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        keterangan: keterangan,
        telpDiangkat: telpDiangkat,
        responDitelp: responDitelp,
        responBaik: responBaik,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate("/");
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating) return <Loading />;

  return (
    <div className="mx-10 my-5">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Konfirmasi Konseling
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Konfirmasi konseling, Isi saran pada form dibawah ini.
        </p>
      </div>
      <form onSubmit={simpan}>
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-start-1 sm:col-span-2 mb-4">
            <label className="block font-sans text-sm leading-6 text-black">
              Telpon Diangkat
            </label>
            <div className="grid grid-cols-3 gap-3">
              {itemtelpDiangkat &&
                itemtelpDiangkat.map((item, index) => (
                  <Radio
                    key={`kat-${index}`}
                    name={item.name}
                    value={item.value}
                    setValue={telpDiangkat}
                    onClick={() => settelpDiangkat(item.value)}
                  />
                ))}
            </div>
          </div>

          <div className="sm:col-start-3 sm:col-span-2">
            <label className="block font-sans text-sm leading-6 text-black">
              Respon Ditelpon
            </label>
            <div className="grid grid-cols-3 gap-3">
              {itemresponDitelp &&
                itemresponDitelp.map((item, index) => (
                  <Radio
                    key={`kat-${index}`}
                    name={item.name}
                    value={item.value}
                    setValue={responDitelp}
                    onClick={() => setresponDitelp(item.value)}
                  />
                ))}
            </div>
          </div>

          <div className="sm:col-start-1 sm:col-span-2">
            <label className="block font-sans text-sm leading-6 text-black">
              Ketika di telpon di respon dengan baik
            </label>
            <div className="grid grid-cols-3 gap-3">
              {itemresponBaik &&
                itemresponBaik.map((item, index) => (
                  <Radio
                    key={`kat-${index}`}
                    name={item.name}
                    value={item.value}
                    setValue={responBaik}
                    onClick={() => setresponBaik(item.value)}
                  />
                ))}
            </div>
          </div>

          <div className="sm:col-start-1 sm:col-span-4">
            <TextArea
              label="Saran Konseling"
              name="keterangan"
              val={keterangan}
              set={setKeterangan}
              rows={4}
              placeholder="Silahkan isi saran..."
            />
            <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
              Saran Konseling untuk pemohon
            </p>
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiOutlineArrowLeft size={18} />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            disabled={isMutating}
            className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiOutlineSave size={18} />
            </span>
            <span>Konfirmasi</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default KonfirmasiIndex;
