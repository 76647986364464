import React from "react";
import { NavLink } from "react-router-dom";
import { HiOutlineHome, HiOutlineUserGroup } from "react-icons/hi";

const Sidebar = () => {
  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800 fixed z-20">
      <div className="fixed flex flex-col top-0 left-0 w-64 bg-white h-full border-r">
        <div className="flex items-center justify-center h-24 border-b">
          <div className="font-sans font-bold text-sm">ADMIN KONSELOR</div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-4 space-y-1">
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Navigation
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineHome size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Dashboard
                </span>
              </NavLink>
            </li>
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Konselor
                </div>
              </div>
            </li>

            <li>
              <NavLink
                to="konseling"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineUserGroup size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Semua Konseling
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
