import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import jwtDecode from "jwt-decode";
import TableDataKonseling from "../DataKonseling/Table";

import { FcHome } from "react-icons/fc";

const Home = () => {
  const navigate = useNavigate();
  const [namaUser, setNamaUser] = useState("");

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      } else {
        setNamaUser(decoded.namaUser);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div>
      <Header titleHead="Home" icon={<FcHome />} />
      <div className="p-4">
        <p className="font-sans text-sm">
          Selamat Datang, <strong>{namaUser}</strong>!
        </p>
      </div>
      <hr />
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700 pt-10 px-4">
          Data hari ini
        </h2>
      </div>
      <TableDataKonseling />
    </div>
  );
};

export default Home;
