import React from "react";

import { useParams } from "react-router-dom";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";

import { API_URL_TES } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const DetailKonseling = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/psikolog/konseling-detail/${id}`,
    sendRequestGet
  );

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div>
      <div className="grid grid-cols-3 m-4 font-sans text-sm gap-y-2">
        <div className="col-start-1 col-span-1 text-gray-400">NIK</div>
        <div className="col-span-2">{data.nik}</div>

        <div className="col-start-1 col-span-1 text-gray-400">Nomor HP</div>
        <div className="col-span-2">{data.noHp}</div>

        <div className="col-start-1 col-span-1 text-gray-400">Kategori SIM</div>
        <div className="col-span-2">{data.katSim}</div>

        <div className="col-start-1 col-span-1 text-gray-400">Jenis SIM</div>
        <div className="col-span-2">{data.jenSim}</div>

        <div className="col-start-1 col-span-1 text-gray-400">
          Tanggal Konseling
        </div>
        <div className="col-span-2">{data.tglKonseling}</div>

        <div className="col-start-1 col-span-1 text-gray-400">Jadwal</div>
        <div className="col-span-2">{data.jadwal}</div>
        <div className="col-start-1 col-span-1 text-gray-400">SARAN</div>
        <div className="col-span-2">{data.keterangan}</div>
      </div>
    </div>
  );
};

export default DetailKonseling;
