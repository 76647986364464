import React, { useState, useEffect, Fragment } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

import { API_URL_TES } from "../../config/index";
import { HiOutlineDocumentText } from "react-icons/hi";

import ErrorAccess from "../ErrorPage/ErrorAccess";
import { useParams } from "react-router-dom";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import { jensimLabel } from "../../function/Labelcolor";
import Loading from "../Loading";
import Paging from "../../components/Paging";
import Modal from "./Modal";

const DataTes = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemPage, setItemPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [fieldQuery, setFieldQuery] = useState("nik");
  const [keywordQuery, setKeywordQuery] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [pemohonId, setPemohonId] = useState("");

  const modalShow = (idP) => {
    setShowModal(true);
    setPemohonId(idP);
  };

  const { id } = useParams();

  const { data: datakonseling, isLoading: isLoadingKonseling } = useSWR(
    `${API_URL_TES}/psikolog/konseling/${id}`,
    sendRequestGet
  );

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/psikolog/pemohon?field=${fieldQuery}&keyword=${keywordQuery}&page=${page}&limit=${limit}`,
    sendRequestGet
  );

  // console.log(data);

  useEffect(() => {
    // get refresh token
    checkToken();
    setFieldQuery("nik");
    if (!isLoadingKonseling) {
      setKeywordQuery(datakonseling.nik);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const changePage = ({ selected }) => {
    setItemPage(selected);
    setPage(selected + 1);
  };

  if (isLoading || isLoadingKonseling) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
          <thead className="border-b border-t text-gray-600 bg-white">
            <tr>
              <th scope="col" className="p-2 border-r">
                #
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                PEMOHON
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TEMPAT, TGL. LAHIR
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                L/P
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                PDDK
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                ALAMAT
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                SIM
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TGL. TES
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                HASIL TES
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                STATUS
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                CREATED AT
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                UPDATED AT
              </th>
              <th scope="col" className="px-2 py-3">
                <span>AKSI</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              <>
                {data.docs.map((val, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="p-2 border-r">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                          <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        <span className="font-bold">{val.namaLengkap}</span>
                        <br />
                        <span className="text-gray-600">{val.nik}</span>
                        <br />
                        ---
                        <br />
                        <span className="text-gray-600">{val.noHp}</span>
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        <span className="">{val.tmpLahir}</span>
                        <br />
                        {val.tglLahir}
                      </td>
                      <td className="px-2 py-2 border-r">{val.jenKel}</td>
                      <td className="px-2 py-2 border-r">{val.pdTerakhir}</td>
                      <td className="px-2 py-2 border-r">{val.alamat}</td>
                      <td className="px-2 py-2 border-r text-center">
                        <span className="text-gray-900 font-bold">
                          {val.katSim}
                        </span>
                        <br />
                        {jensimLabel(val.jenSim)}
                      </td>
                      <td className="px-2 py-2 border-r">{val.tglTes}</td>
                      <td className="px-2 py-2 border-r text-center">
                        {val.hasilTes !== null ? (
                          <>
                            {val.hasilTes === "MS" ? (
                              <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-emerald-500 bg-emerald-50 rounded-full">
                                {val.hasilTes}
                              </span>
                            ) : (
                              <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-red-500 bg-red-50 rounded-full">
                                {val.hasilTes}
                              </span>
                            )}
                          </>
                        ) : (
                          <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-orange-500 bg-orange-50 rounded-full">
                            Belum Selesai
                          </span>
                        )}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {val.stSelesai === "Y" ? (
                          <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-emerald-500 bg-emerald-50 rounded-full">
                            Selesai
                          </span>
                        ) : (
                          <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-red-500 bg-red-50 rounded-full">
                            Belum Tuntas
                          </span>
                        )}
                      </td>
                      <td className="px-2 py-2 border-r">{val.createdAt}</td>
                      <td className="px-2 py-2 border-r">{val.updatedAt}</td>
                      <td className="px-2 py-2 text-center border-r">
                        <div className="inline-flex flex-row gap-2">
                          <button
                            type="button"
                            onClick={() => modalShow(val._id)}
                            className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                          >
                            <span className="mr-2">
                              <HiOutlineDocumentText size={18} />
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr className="bg-white border-b hover:bg-gray-50">
                <td colSpan="11" className="px-2 py-2 text-center">
                  Data tidak ditemukan
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isLoading && (
        <Paging
          totalDocs={data.totalDocs}
          totalPages={data.totalPages}
          page={itemPage}
          limit={limit}
          setLimit={setLimit}
          changePage={changePage}
        />
      )}

      {showModal && (
        <Modal
          idPem={pemohonId}
          show={showModal}
          setShow={() => setShowModal(!showModal)}
        />
      )}
    </>
  );
};

export default DataTes;
