import Root from "./root";
import Login from "../pages/Login";

import Home from "../pages/Home";

import DataKonseling from "../pages/DataKonseling";
import TableDataKonseling from "../pages/DataKonseling/Table";

import ProfileKonseling from "../pages/DataKonseling/ProfileKonseling";
import Datates from "../pages/DataKonseling/DataTes";
import Konfirmasi from "../pages/DataKonseling/KonfirmasiIndex";
import RootKonseling from "../pages/DataKonseling/RootKonseling";

import DataSemuaKonseling from "../pages/DataSemuaKonseling";
import TableDataSemuaKonseling from "../pages/DataSemuaKonseling/Table";

import RootDetailKonseling from "../pages/DataSemuaKonseling/RootDetailKonseling";
import DetailKonseling from "../pages/DataSemuaKonseling/DetailKonseling";
import DataTesPemohon from "../pages/DataSemuaKonseling/DataTesPemohon";

import ErrorPage from "../pages/ErrorPage";

export const Paths = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "konseling",
        element: <DataSemuaKonseling />,
        breadcrumb: "Data Semua Konseling",
        children: [
          {
            index: true,
            element: <TableDataSemuaKonseling />,
            breadcrumb: "Data Konseling",
          },
          {
            path: ":id",
            element: <RootKonseling />,
            breadcrumb: "Detail Konseling",
            children: [
              {
                path: "profile-konseling",
                element: <ProfileKonseling />,
                breadcrumb: "Profile Konseling",
              },
              {
                path: "data-tes",
                element: <Datates />,
                breadcrumb: "Data Tes",
              },
              {
                path: "konfirmasi",
                element: <Konfirmasi />,
                breadcrumb: "Konfirmasi",
              },
            ],
          },
          {
            path: "detail/:id",
            element: <RootDetailKonseling />,
            breadcrumb: "Detail Konseling",
            children: [
              {
                path: "detail-konseling",
                element: <DetailKonseling />,
                breadcrumb: "Detail Konseling",
              },
              {
                path: "data-tes-pemohon",
                element: <DataTesPemohon />,
                breadcrumb: "Data Tes Pemohon",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
];
