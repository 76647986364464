import React, { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";

import { API_URL_TES } from "../../config/index";
import { HiSearch } from "react-icons/hi";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestDelete, sendRequestGet } from "../../swr";
import { jensimLabel } from "../../function/Labelcolor";
import Loading from "../Loading";

const Table = () => {
  const navigate = useNavigate();

  const { isMutating } = useSWRMutation(
    `${API_URL_TES}/psikolog/konseling`,
    sendRequestDelete
  );

  const { data, isLoading } = useSWR(
    `${API_URL_TES}/psikolog/konseling-today`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  if (isLoading || isMutating) return <Loading />;

  return (
    <>
      <div className="px-2 mb-3"></div>
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
          <thead className="border-b border-t text-gray-600 bg-white">
            <tr>
              <th scope="col" className="p-2 border-r">
                #
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                PEMOHON
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                SIM
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TGL. KONSELING
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                JADWAL
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                STATUS
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TELPON DIANGKAT
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TELPON DIRESPON
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                DIRESPON DENGAN BAIK
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                CREATED AT
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                UPDATED AT
              </th>
              <th scope="col" className="px-2 py-3">
                <span>AKSI</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              <>
                {data.map((val, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="p-2 border-r">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                          <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        <span className="font-bold">{val.nik}</span>
                        <br />
                        ---
                        <br />
                        <span className="text-gray-600">{val.noHp}</span>
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        <span className="text-gray-900 font-bold">
                          {val.katSim}
                        </span>
                        <br />
                        {jensimLabel(val.jenSim)}
                      </td>
                      <td className="px-2 py-2 border-r">{val.tglKonseling}</td>
                      <td className="px-2 py-2 border-r">{val.jadwal}</td>
                      <td className="px-2 py-2 border-r text-center">
                        {val.statusKonseling === "Y" ? (
                          <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-emerald-500 bg-emerald-50 rounded-full">
                            Selesai
                          </span>
                        ) : (
                          <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-red-500 bg-red-50 rounded-full">
                            Belum
                          </span>
                        )}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {val.telpDiangkat === null ? (
                          ""
                        ) : (
                          <div>
                            {val.telpDiangkat === "Y" ? (
                              <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-emerald-500 bg-emerald-50 rounded-full">
                                Iya
                              </span>
                            ) : (
                              <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-red-500 bg-red-50 rounded-full">
                                Tidak
                              </span>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {val.telpDiangkat === null ? (
                          ""
                        ) : (
                          <div>
                            {val.responDitelp === "Y" ? (
                              <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-emerald-500 bg-emerald-50 rounded-full">
                                Iya
                              </span>
                            ) : (
                              <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-red-500 bg-red-50 rounded-full">
                                Tidak
                              </span>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="px-2 py-2 border-r text-center">
                        {val.telpDiangkat === null ? (
                          ""
                        ) : (
                          <div>
                            {val.responBaik === "Y" ? (
                              <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-emerald-500 bg-emerald-50 rounded-full">
                                Iya
                              </span>
                            ) : (
                              <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-red-500 bg-red-50 rounded-full">
                                Tidak
                              </span>
                            )}
                          </div>
                        )}
                      </td>

                      <td className="px-2 py-2 border-r">{val.createdAt}</td>
                      <td className="px-2 py-2 border-r">{val.updatedAt}</td>
                      <td className="px-2 py-2 text-center border-r">
                        <div className="inline-flex flex-row gap-2">
                          {val.statusKonseling === "N" && (
                            <Link
                              to={`/konseling/${val._id}/profile-konseling`}
                              className="text-white px-2 py-2 bg-blue-400 hover:bg-blue-300 rounded-full"
                            >
                              <span>
                                <HiSearch size={14} />
                              </span>
                            </Link>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr className="bg-white border-b hover:bg-gray-50">
                <td colSpan="11" className="px-2 py-2 text-center">
                  Data tidak ditemukan
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
