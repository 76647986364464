import React from "react";
import { HiCheck } from "react-icons/hi";

const Radio = ({ name, value, setValue, onClick }) => {
  return (
    <div
      className={
        value === setValue
          ? "flex-1 flex items-center rounded-[10px] hover:border-blue-600 cursor-pointer"
          : "flex-1 flex items-center rounded-[10px] hover:border-blue-600 cursor-pointer"
      }
      onClick={onClick}
    >
      {value === setValue ? (
        <span className="md:w-6 md:h-6 w-4 h-2 rounded-full bg-blue-500 mr-2 flex items-center justify-center">
          <HiCheck color="#FFFFFF" size={18} />
        </span>
      ) : (
        <span className="md:w-6 md:h-6 w-4 h-4 rounded-full bg-gray-300 mr-2"></span>
      )}

      <span className="md:text-lg text-sm font-poppins-bold text-gray-600">
        {name}
      </span>
    </div>
  );
};

export default Radio;
