import React from "react";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";
import { API_URL_TES } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const Modal = ({ idPem, show, setShow }) => {
  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/psikolog/pemohon/${idPem}`,
    sendRequestGet
  );

  const tableSub = (row, index) => {
    const rowTbl = [];

    rowTbl.push(
      <tr key={index} className="bg-white border-b hover:bg-gray-50">
        <td className="p-2 border-r text-center">{index + 1}</td>
        <td className="px-2 py-2 text-gray-900 font-bold border-r">
          {row.namaScoring}
        </td>
        <td className="px-2 py-2 border-r text-center">{row.totalNilai}</td>
        <td className="px-2 py-2 border-r">{row.namaKategori}</td>
      </tr>
    );

    row.subScoring.map((row2, index2) =>
      rowTbl.push(
        <tr
          key={`sub-${index2}`}
          className="bg-white border-b hover:bg-gray-50"
        >
          <td className="p-2 border-r"></td>
          <td className="px-2 py-2 text-gray-900 border-r">
            {row2.namaScoring}
          </td>
          <td className="px-2 py-2 border-r text-center">{row2.totalNilai}</td>
          <td className="px-2 py-2 border-r">{row2.namaKategori}</td>
        </tr>
      )
    );

    return rowTbl;
  };

  const tableMaster = (row, index) => {
    return (
      <tr key={index} className="bg-white border-b hover:bg-gray-50">
        <td className="p-2 border-r text-center">{index + 1}</td>
        <td className="px-2 py-2 text-gray-900 font-bold border-r">
          {row.namaScoring}
        </td>
        <td className="px-2 py-2 border-r text-center">{row.totalNilai}</td>
        <td className="px-2 py-2 border-r">{row.namaKategori}</td>
      </tr>
    );
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      {show ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-contrast-50">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-3xl font=semibold">Scoring</h3>
                  <button
                    className="bg-transparent border-0 float-right"
                    onClick={setShow}
                  >
                    <span className="opacity-7 h-6 w-6 text-xl block py-0 rounded-full">
                      x
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="relative overflow-x-auto mb-3">
                    <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
                      <thead className="border-b border-t text-gray-600 bg-white">
                        <tr>
                          <th scope="col" className="p-2 border-r">
                            #
                          </th>
                          <th scope="col" className="px-2 py-3 border-r">
                            SCORING
                          </th>
                          <th scope="col" className="px-2 py-3 border-r">
                            TOTAL NILAI
                          </th>
                          <th scope="col" className="px-2 py-3 border-r">
                            KATEGORI NILAI
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.hitungNilai.length > 0 && (
                          <>
                            {data.hitungNilai.map((row, index) => {
                              return row.subScoring.length > 0
                                ? tableSub(row, index)
                                : tableMaster(row, index);
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={setShow}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
